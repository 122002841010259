import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Image from "../../common/Image"
import { Avatar } from "@material-ui/core"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F9F9FF",
    paddingBottom:"50px "
  },
  title: {
    color: "#2278CF",
    fontSize: "2em",
    paddingTop: "2em",
    textAlign: "center",
    backgroundColor: "#F9F9FF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6em",
      padding: "1.5em",
    },
  },
  description: {
    margin: "0 auto",
    padding: "3% 25%",
    fontWeight: "400",
    fontSize: "1.5em",
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 8rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 2rem",
      fontSize: "1.4em",
    },
  },
  container: {
    display: "flex",
    width: "100%",
    height: 200,
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "100%",
    },
  },
  paper: {
    width: "33.33%",
    display: "flex",
    margin: "20px 20px",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      margin: "20px auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      height: "160px",
    },
  },
  avatar: {
    width: "30%",
    padding: "20px 10px",
    position: " initial !important",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 10px",
    },
  },
  avatarContent: {
    width: "100px",
    height: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
  },
  avatarContentChild: {
    width: "100%",
    height: "100%",
  },
  cardTitleList: {
    width: "70%",
    margin: "17px",
  },
  cardTitle: {
    height: "60px",
    fontSize: "15px",
    textAlign:"center",
    fontWeight: 600,
  },
  button: {
    margin: "1rem auto",
    display: "flex",
    width: "120px",
    fontSize: "18px",
    padding: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "100px",
    },
  },
}))

export default function SiteReference({ data }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.title} gutterBottom variant="h3">
        {data.title}
      </Typography>
        <Typography className={classes.description} gutterBottom variant="h6">
          {data.description}
        </Typography>
      <div className={classes.container}>
        {data.items.map((item, index) => {
          return (
            <Paper className={classes.paper}>
              <div className={classes.avatar}>
                <Avatar aria-label="recipe" className={classes.avatarContent}>
                  {
                    <Image
                      {...item.img}
                      className={classes.avatarContentChild}
                    />
                  }
                </Avatar>
              </div>
              <div className={classes.cardTitleList}>
                  <div>
                    <Typography
                      className={classes.cardTitle}
                      gutterBottom
                      variant="subtitle1"
                    >
                      {item.title}
                    </Typography>
                  </div>
                <div >
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href={item.link}
                  >
                    Chi tiết
                  </Button>
                </div>
              </div>
            </Paper>
          )
        })}
      </div>
    </div>
  )
}
