import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Image from "../../common/Image";
import { Avatar } from '@material-ui/core';
import TableContainer from "@material-ui/core/TableContainer";
import UserCommentChild from "./UserCommentChild";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F9F9FF",
  },
  title:{
    fontSize:"2em",
    padding: "2em",
    textAlign:"center",
    [theme.breakpoints.down("xs")]: {
      fontSize:"1.6em",
    },
  },
  container: {
    overflow:"hidden",
    margin:"0 auto",
    maxWidth:"800px",
    padding:"20px 0",
    borderRadius:"5px",
    [theme.breakpoints.down("xs")]: {
      height:"100%",
      padding:"20px 0"
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "2% auto",
    width:"100%",
    height:"120px",
    maxWidth: 450,
    boxShadow: "4px 4px 15px 3px #D0D2D4",
    "&:hover": {
      transform: "translateY(-3px)",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth:"65%",
      height:"80px",
    },
  },

  avatarTop:{
    width:"100%",
    height:"100%"
  },

  avatarCardList:{
    width: theme.spacing(10),
    height: theme.spacing(10),
    left: "10%",
    top:"1.5%",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      left: "5%",
      top:"0.5%",

    },
  },

  avatarCardListChild:{
    width:"100%",
    height:"100%",
    position:" initial !important",
  },
  cardTitleList:{
    margin:"5% auto",
    padding:"5px 0",
    width:"60%",
    [theme.breakpoints.down("xs")]: {
      margin:"5% auto",
      padding:"3px 0px",
      width:"60%",
    },
  },
  cardLabelList:{
    margin:"auto",
    fontSize:"0.8em",
    color:"#1C408D",
    [theme.breakpoints.down("xs")]: {
      fontSize:"80%",
      margin:"auto",

    },
  },
  cardTitleListComments:{
    margin:"2% auto",
    fontSize:"1em",
    fontWeight:"bold",
    color:"#1C408D",
    [theme.breakpoints.down("xs")]: {
      fontSize:"110%",
      color:"#1C408D",
    },
  },
  description:{
    margin: '5% auto',
    width: "103%",
    height: "30px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      width: "103%",
      fontSize:"80%",
      height: "16px",
    },
  },
  

}));

export default function UserComments({ data }) {
  const classes = useStyles();
  const [value, setValue] = useState(0)

  function  handlChange(index, e) {
    setValue(index)
  }


  return (
    <div className={classes.root} id="comment">
      
      <Typography className={classes.title} gutterBottom variant="h3" color="primary">
        {data.title}
      </Typography>
      <TableContainer className={classes.container}>
        {data.card.map((item,index) => {
          return value !== index ?(
            <Paper className={classes.paper} onClick={() => handlChange(index)}>
              <Grid container spacing={2}>
                <Grid item>
                  <div className={classes.avatarTop}>
                    <Avatar aria-label="recipe" className={classes.avatarCardList}>
                      {<Image {...item.img}  className={classes.avatarCardListChild}/>}
                    </Avatar>
                  </div>
                  
                </Grid>
                <div className={classes.cardTitleList}>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                  <Typography className={classes.cardLabelList} gutterBottom variant="subtitle1">
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                  <Typography className={classes.cardTitleListComments} gutterBottom variant="subtitle1">
                    {item.subtitle}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                  <Typography gutterBottom variant="subtitle1" className={classes.description}>
                    {item.description}
                  </Typography>
                </Grid>
                </div>
              </Grid>
            </Paper>
          ): (
              <UserCommentChild data={item}/>
          )
        })}  
      </TableContainer>
    </div>
  );
}