import React from "react"
import { graphql } from "gatsby"
import withRoot from "../components/shell/withRoot"
import SEO from "../components/seo"
import Top from "../components/top/pageContents/Top"

function HomePage({ data }) {
  return (
    <React.StrictMode>
      <SEO title="Website học tiếng Nhật Miễn Phí" />
      <Top data={data.topJson}  />
    </React.StrictMode>
  )
}

export default withRoot(HomePage)
export const query = graphql`
  {
    topJson {
      header {
        label
        subdescription
        sublabel
        title
        description
      }
      reason {
        title
        label {
          description
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      feature {
        label
        title
        avatar {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        description
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      supportTop {
        title
        categories {
          description
          label
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      corporation {
        description
        title
        items {
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      siteRefenence {
        description
        title
        items {
          link
          title
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      usercomments {
        title
        card {
          description
          subtitle
          title
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
