import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Image from "../../common/Image"
import { Avatar, CardContent } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  banner: {
    position: "relative",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  contentiImg: {
    width: "250px",
    height: "194px",
    borderRadius: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "320px",
      height: "230px",
    },
    [theme.breakpoints.up("md")]: {
      width: "350px",
      height: "245px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "400px",
      height: "245px",
    },
  },
  avatar: {
    position: "absolute",
    top: "22px",
    left: "-80px",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      left: "-97px",
      width: "100%",
      height: "100%",
    },
  },
  avatarContent: {
    width: "50px",
    height: "50px",
    [theme.breakpoints.up("sm")]: {
      width: "70px",
      height: "70px",
    },
  },
  avatarChild: {
    width: "100%",
    height: "100%",
    position: " initial !important",
  },
  title: {
    color: "#FFFFFF",
    position: "absolute",
    top: "115px",
    left: "120px",
    width: "50%",
    wordBreak: "break-word",
    fontSize: "100%",
    [theme.breakpoints.down("xs")]: {
      top: "43%",
      left: "15%",
      width: "70%",
      fontSize: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: "44%",
      left: "50px",
      fontSize: "100%",
      width: "70%",
    },
  },
  description: {
    color: "#FFFFFF",
    position: "absolute",
    top: "110px",
    left: "95px",
    width: "67%",
    wordBreak: "break-word",
    fontSize: "100%",
    [theme.breakpoints.down("xs")]: {
      top: "40%",
      left: "16.5%",
      fontSize: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: "43%",
      left: "55px !important",
      width: "71%",
      fontSize: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      top: "40%",
      left: "24.5%",
      width: "51%",
      fontSize: "100%",
    },
  },

}))

export default function FeatureCard({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.banner}>
      <div className={classes.img}>
        <Image {...data.img} className={classes.contentiImg} />
      </div>
      <div className={classes.avatar}>
        <Avatar aria-label="recipe" className={classes.avatarContent}>
          <Image {...data.avatar} className={classes.avatarChild} />
        </Avatar>
      </div>

      <CardContent>
        <Typography className={classes.title}>{data.title}</Typography>
        <Typography className={classes.description}>
          {data.description}
        </Typography>
      </CardContent>
    </div>
  )
}
