import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Background from "../../../data/top/media/banner-top.png";
import Particle from "./Particle"

const useStyles = makeStyles(theme => ({
  root: {
    background: `transparent url(${Background})`,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `66% `,
    width: "100%",
    height: "calc(100vh - 60px)",
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    marginBottom: "20%",
    zIndex: "99",
    [theme.breakpoints.down("md")]: {
      marginBottom: "50%",
    },
  },
  title: {
    color: "#fff",
    textAlign: "center",
    padding: "15vh 0 7vh 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4em",
    },
  },
  text: {
    color: "#fff",
    textAlign: "center",
  },
  description: {
    fontSize: "2em",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  subdescription: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  particle: {
    width: "100%",
    height: "100%",
    zIndex: "1",
  },
}))

export default function Header({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.particle}>
        <Particle />
      </div>

      <div className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {data.title}
        </Typography>
        <div className={classes.text}>
          <Typography variant="h4" className={classes.description}>
            {data.description}
          </Typography>
          <Typography variant="h4" className={classes.subdescription}>
            {data.subdescription}
          </Typography>
        </div>
      </div>
    </div>
  )
}
