
import React from "react"
import Corporation from '../sections/Corporation'
import SiteReference from '../sections/SiteReference'
import Reason from "../sections/Reason"
import SupportTop from "../sections/SupportTop"
import { makeStyles } from "@material-ui/core/styles"
import Feature from "../sections/Feature"
import HeaderTop from "../sections/HeaderTop"
import UserComments from "../sections/UserComments"

const useStyles = makeStyles(theme => ({
    root:{
        background: "#f5f5f599",
        overflow: "hidden"
    }
}))
const Home  = ({data}) => { 
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <HeaderTop data={data.header}/>
      <Feature data={data.feature}/>
      <Reason data={data.reason}/>
      <SupportTop data={data.supportTop}/>
      <UserComments data ={data.usercomments} />
      <Corporation　data = {data.corporation}/>
      <SiteReference data = {data.siteRefenence}/>
    </div>
  )
}
export default Home
