import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Image from "../../common/Image";
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginBottom:"20px",
    width:"100%",
    height:"350px",
    maxWidth: 700,
    boxShadow: "4px 4px 15px 3px #D0D2D4",
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      height:"250px",
    },
  },
  avatarBottom:{
    width:"100%",
    height:"100%"
  },
  avatarCard: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    left: "80%",
    top:"6%",
    boxShadow:"0 10px 10px 0 rgb(0 0 0 / 10%), 0 6px 10px 0 rgb(0 0 0 / 20%)",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      left: "15%",
      top:"10%",
    },
  },
  avatarCardChild:{
    width:"100%",
    height:"100%",
    position:" initial !important",
  },
  cardTitle:{
    marginTop: "8%",
    marginLeft: "10%",
    width:"60%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-25%",
      marginLeft: "45%",
    },
  },
  cardLabel:{
    fontSize:"1em",
    margin:"5% auto",
    color:"#FD4967",
    [theme.breakpoints.down("xs")]: {
      fontSize:"80%",
      margin:"10% auto",

    },
  },
  cardSubTitle:{
    fontSize:"1.6em",
    textAlign:"center",
    fontWeight:"bold",
    color:"#1C408D",
    [theme.breakpoints.down("xs")]: {
      fontSize:"110%",
      textAlign:"center",
    },
  },
  description:{
    margin:"8% auto",
    fontSize:"1.2em",
    padding : "0 10% 15% 10%",
    color:"#1C408D",
    [theme.breakpoints.down("md")]:{
      padding :"0 8% 0 8%"
    },
    [theme.breakpoints.down("sm")]:{
    padding :"0 8% 0 8%"
    },
    [theme.breakpoints.down("xs")]: {
      margin:"10% auto",
    },
  }
}));

export default function UserComments({ data }) {
    const classes= useStyles(); 

  return ( 
        <div>
            <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item>
                <div className={classes.avatarBottom}>
                    <Avatar aria-label="recipe" className={classes.avatarCard}>
                    {<Image {...data.img}  className={classes.avatarCardChild}/>}
                    </Avatar>
                </div>
                </Grid>
                <div className={classes.cardTitle}>
                <Grid  item xs={12} sm container direction="column" spacing={2}>
                <Typography className={classes.cardLabel} gutterBottom variant="subtitle1">
                    {data.title}
                </Typography>
                </Grid>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                <Typography className={classes.cardSubTitle} gutterBottom variant="subtitle1">
                    {data.subtitle}
                </Typography>
                </Grid>
                </div>
                <div>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                    <Typography className={classes.description} gutterBottom variant="subtitle1">
                    {data.description}
                    </Typography>
                </Grid>
                </div>
                
            </Grid>
            </Paper>
        </div>
  );
}