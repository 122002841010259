
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core";
import Image from "../../common/Image";


const useStyles = makeStyles(theme => ({
  root: {
    width:"100%",
    backgroundColor: "#E3EBF6",
    display:"flex",
    flexDirection:"column",
    alignItems:"center"
  },
  title: {
    color: "#2278CF",
    fontSize: "2em",
    padding: '3rem 2rem',
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
      padding: '2rem 1rem',
      
    },
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent:"center",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
  },
  img: {
    width: 200,
    height: 200,
    margin:"20px 30px",
    [theme.breakpoints.down("md")]: {
      width: 150,
      height: 150,
      margin:"20px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
      height: 150,
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
    },
  },
  description: {
    fontSize: 24,
    padding: '3rem 5rem',
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      padding: '2rem 1rem',
    },
  }
}))

const Corporation = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
        <Typography className={classes.title}>
          {data.title}
        </Typography>
        <div className={classes.content}>
          {data.items.map((item) => {
            return (
              <div className={classes.image}>
                <Image className={classes.img} {...item.img} />
              </div>
            )
          })}
        </div>
        <Typography className={classes.description} variant="subtitle1">
          {data.description}
        </Typography>
    </div>
  )
}
export default Corporation