import { Card, Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Image from "../../common/Image";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: "40px",
    backgroundColor: "#E3EBF6",
  },
  title: {
    color: "#2278CF",
    textAlign: "center",
    position: "auto",
    fontSize: "2em",
    padding: "60px 0px 25px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "35px 0 20px 0",
    },
  },
  root2: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  card: {
    maxWidth: 320,
    height: 425,
    borderRadius: "5px",
    margin: "30px auto",

    [theme.breakpoints.down("xs")]: {
      maxWidth: "75%",
      height: 370,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "29%",
      height: 370,
      margin: "0px 0px 30px 25px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "27%",
      height: 400,
      margin: "30px auto",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 350,
      height: 425,
      margin: "30px auto",
    },
  },
  media: {
    display: "block",
    objectFit: "cover",
    width: "100%",
    height: "65%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "50%",
    },
    [theme.breakpoints.up("md")]: {
    width:"100%",
    height:"63%",
      },    
  },
  label: {
    textAlign: "center",
    color: "#2278CF",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  description: {
    fontSize: "1.2em",
    paddingBottom: "0px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    },
  },
}))
export default function SupportTop({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {data.title}
      </Typography>
      <div className={classes.root2}>
        {data.categories.map((item, index) => {
          return (
            <Card className={classes.card}>
              <Image {...item.img} className={classes.media} />
              <CardContent className={classes.content}>
                <Typography
                  className={classes.label}
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.description}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          )
        })}
      </div>
    </div>
  )
}
