import React from "react"
import FeatureCard from "../sections/FeatureCard"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
   
    backgroundColor:"#E3EBF6",
  },

  gridContainer: {
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "60px",
  },
  title: {
    fontSize: "2em",
    color: "#2278CF",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
      padding: "50px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2em",
      padding: "60px",
    },
  },
}))

export default function Feature({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.root} >
      <center id="feature">
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          className={classes.title}
        >
          Đặc trưng của World Japanese Dojo
        </Typography>
        <Grid
          container
          spacing={0}
          className={classes.gridContainer}
          justify="center"
        >
          {data.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} md={5}>
                <FeatureCard data={item} />
              </Grid>
            )
          })}
        </Grid>
      </center>
    </div>
  )
}
