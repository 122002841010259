
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Image from "../../common/Image";

const useStyles = makeStyles(theme => ({
  header:{
    padding: "40px 0 75px 0",
    backgroundColor: "#F9F9FF",
   },
  editImag: {
    position: "relative",
  },
  title: {
    color: "#2278CF",
    textAlign: "center",
    fontSize: "2em",
    padding: "4% 0 4% 0",
    margin: "0 37px 0 37px",
  },

  description: {
    position: "absolute",
    top: "40px",
    left: "270px",
    margin: "0px 140px 0 100px",
    fontSize: "1.4em",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 35px 0 45px",
      top: "24%",
      left: "18%",
      fontSize: "1.4em",
    },
    [theme.breakpoints.between('sm', 'md')]: {
      top: "17.3%",
      left: "13.8%",
      marginRight: "12%",
      fontSize: "1.4em",
    },
  },

  img: {
    "& img":{
      position:"static !important"
    },
    position: "relative",
    padding: "30px 0 30px 0",
    left: "250px",
    maxWidth: "75px",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 50px 0 49px",
      left: "-4%",
      maxWidth: "13%",
      padding : "47px 0 47px 0"
    },
    [theme.breakpoints.between('sm', 'md')]: {
      top: "73%",
      left: "11.8%",
      fontSize: "100%"
    },

  },
}))

const Reason = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={classes.header} >
      <Typography variant="h3" className={classes.title}> {data.title}</Typography>

      {data.label.map((item, index) => {
        return (
          <div className={classes.editImag} >
            <Image {...item.img} className={classes.img} />
            <Typography variant="body2" className={classes.description} >
              {item.description}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}
export default Reason